import Vue from 'vue'
import Router from 'vue-router'
import main from '../components/main.vue'
import namelist from '../components/namelist.vue'


Vue.use(Router)

const router = new Router({
  routes : [
    { path:'/', component: main },
    { path:'/namelist', component: namelist }
  ]
  
})


export default router