<template>
  <div class="body1">
    <div class="scene">
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <div class="wall wall-back"></div>
      </div>
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <div class="wall wall-back"></div>
      </div>
    </div>

    <div
      style="
        font-size: 40px;
        color: white;
        position: absolute;
        top: 20px;
        left: 20px;
      "
    >
      HKUPootal 周末派对
    </div>
    <div
      style="
        font-size: 40px;
        color: white;
        position: absolute;
        bottom: 20px;
        right: 20px;
      "
    >
      {{ group_name }} {{ joined_and_seated_user_count }}/{{ all_user_count }}
    </div>

    <div>
      <transition name="el-fade-in-linear">
        <div
          style="
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            height: 80%;
            text-align: center;
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows: 25% 25% 25% 25%;
          "
          v-show="show0"
        >
          <div
            style="font-size: 60px; color: white; margin: 30px"
            v-for="(item, index) in seated_user_name_list"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </transition>
    </div>

    <div
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
      "
    >
      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show1">
            <div style="font-size: 80px; color: white">{{group_name}} 欢迎</div>
            <div style="font-size: 80px; color: white">
              <span v-for="(item, index) in joined_user_name_list" :key="index"
                >{{ item
                }}{{
                  index + 1 == joined_user_name_list.length ? "" : "、"
                }}</span
              >
            </div>
            <el-button
              @click="sit(item)"
              v-for="(item, index) in joined_user_name_list"
              :key="index"
              style="
                font-size: 30px;
                color: black;
                margin: 50px 30px;
                padding: 15px 20px;
                border-radius: 10px;
              "
              >{{ item }}</el-button
            >
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.group_name = this.$route.query.group_name;
    this.getNameList();
  },
  data() {
    return {
      group_name: "",
      all_user_name_list: [],
      joined_user_name_list: [],
      seated_user_name_list: [],
      all_user_count: 0,
      joined_and_seated_user_count: 0,
      show0: false,
      show1: false,
      res: {},
    };
  },
  methods: {
    async getNameList() {
      const { data: res } = await this.$http.post(
        "getNameList.php",
        "group_name=" + this.group_name
      );
      console.log(res);
      if (res.code == 200) {
        if (JSON.stringify(res) != JSON.stringify(this.res)) {
          if (!this.show0 && !this.show1) {
            if (res.joined_user_name_list[0]) {
              // this.show0 = false
              this.show1 = true;
              console.log("1");
            } else {
              this.show0 = true;
              // this.show1 = false
              console.log("2");
            }
          } else if (this.show0) {
            if (res.joined_user_name_list[0]) {
              this.show0 = false;
              this.show1 = false;
              setTimeout(() => {
                this.show1 = true;
              }, 2000);
              console.log("3");
            } else if (
              JSON.stringify(res.seated_user_name_list) !=
              JSON.stringify(this.seated_user_name_list)
            ) {
              this.show0 = false;
              this.show1 = false;
              setTimeout(() => {
                this.show0 = true;
              }, 2000);
              console.log("4");
            } else {
              console.log("5");
            }
          } else if (this.show1) {
            console.log(res.joined_user_name_list);
            console.log(this.joined_user_name_list);
            if (
              JSON.stringify(res.joined_user_name_list) ==
              JSON.stringify(this.joined_user_name_list)
            ) {
              console.log("6");
            } else if (res.joined_user_name_list[0]) {
              this.show0 = false;
              this.show1 = false;
              setTimeout(() => {
                this.show1 = true;
              }, 2000);
              console.log("7");
            } else {
              this.show0 = false;
              this.show1 = false;
              setTimeout(() => {
                this.show0 = true;
              }, 2000);
              console.log("8");
            }
          }
          this.res = res;
          setTimeout(() => {
            this.all_user_name_list = res.all_user_name_list;
            this.joined_user_name_list = res.joined_user_name_list;
            this.seated_user_name_list = res.seated_user_name_list;
            this.all_user_count = res.all_user_count;
            this.joined_and_seated_user_count =
              res.joined_and_seated_user_count;
          }, 1000);
        }
        setTimeout(() => {
          this.getNameList();
        }, 3000);
      } else if (res.code == 401) {
        this.$message.error("组名错误，请检查网页链接");
      } else {
        this.$message.error("出现错误");
      }
    },
    async sit(user_name) {
      const { data: res } = await this.$http.post(
        "sit.php",
        "user_name=" + user_name
      );
      console.log(res);
      this.$message.success(user_name+"已落座");
    },
  },
};
</script>

<style lang="less" scoped>
.body1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.body1 {
  background: #000;
  text-align: center;
}
.body1::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.scene {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  perspective: 15px;
  perspective-origin: 50% 50%;
}

.wrap {
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: move 12s infinite linear;
  animation-fill-mode: forwards;
}

.wrap:nth-child(2) {
  animation: move 12s infinite linear;
  animation-delay: 6s;
}

.wall {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(/img/sg.jpg);
  background-size: cover;
  opacity: 0;
  animation: fade 12s infinite linear;
}

.wrap:nth-child(2) .wall {
  animation-delay: 6s;
}

.wall-right {
  transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
  transform: rotateY(-90deg) translateZ(500px);
}

.wall-top {
  transform: rotateX(90deg) translateZ(500px);
}

.wall-bottom {
  transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
  transform: rotateX(180deg) translateZ(500px);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes move {
  0% {
    transform: translateZ(-500px) rotate(0deg);
  }
  100% {
    transform: translateZ(500px) rotate(0deg);
  }
}
.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active {
  transition: opacity 1000ms linear;
}
div {
  font-family: "Arial";
}
</style>