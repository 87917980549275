<template>
  <div class="body1">
    <div class="scene">
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <div class="wall wall-back"></div>
      </div>
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <div class="wall wall-back"></div>
      </div>
    </div>
    <div
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
      "
    >
      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show0">
            <div style="font-size: 80px; color: white">欢迎加入</div>
            <div style="font-size: 80px; color: white">HKUPootal 周末派对</div>
            <el-button
              @click="action0"
              style="
                font-size: 30px;
                color: black;
                margin-top: 50px;
                padding: 15px 20px;
                border-radius: 10px;
              "
              >加入派对</el-button
            >
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show1">
            <div style="font-size: 40px; color: white">
              微信扫码，进入派对世界
            </div>
            <div style="margin-top: 50px">
              <el-image
                style="width: 300px; height: 300px"
                :src="qrcode_url"
              ></el-image>
            </div>
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show_follow">
            <div style="font-size: 40px; color: white">
              微信扫码，关注公众号
            </div>
            <div style="margin-top: 50px">
              <el-image
                style="width: 300px; height: 300px"
                :src="follow_qr"
              ></el-image>
            </div>
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show2">
            <div style="font-size: 80px; color: white">
              欢迎你，{{ user_name }}
            </div>
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show4">
            <div style="font-size: 80px; color: white">{{sentence1}}</div>
            <div style="font-size: 80px; color: white">{{sentence2}}</div>
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show3">
            <div style="font-size: 80px; color: white">派对限定头像包</div>
            <div style="font-size: 80px; color: white">
              已传送至你的噗噗账号
            </div>
          </div>
        </transition>
      </div>

      <div>
        <transition name="el-fade-in-linear">
          <div v-show="show5">
            <div style="font-size: 80px; color: white">
              请至「{{ user_group }}」落座
            </div>
            <div style="font-size: 80px; color: white">
              Have fun!
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      this.show0 = true;
    }, 3000);
  },
  data() {
    return {
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show_follow: false,
      qrcode_url: "",
      auth_key: "",
      logining: true,
      user_name: "",
      sentence1: "",
      sentence2: "",
      follow_qr: "https://i.boatonland.com/fnjFpmN4RjEFZWz8bfikSkSCbhRThYwT"
    };
  },
  methods: {
    async action0() {
      this.show0 = false;
      const { data: res } = await this.$http.post("getAuth.php");
      console.log(res);
      if (res.code == 200) {
        this.qrcode_url = res.qrcode_url;
        this.auth_key = res.auth_key;
        setTimeout(() => {
          this.show1 = true;
          this.logining = true;
          this.checkLogin();
        }, 2000);
      } else {
        this.$message.error("出现错误");
      }
    },
    async checkLogin() {
      if (!this.logining) {
        return;
      }
      const { data: res } = await this.$http.post(
        "checkAuth.php",
        "auth_key=" + this.auth_key
      );
      console.log(res);
      if (res.code == 200) {
        this.logining = false;
        this.show1 = false;
        this.show_follow = false;
        this.user_name = res.user_name;
        this.user_group = res.user_group;
        this.sentence1 = res.sentence1;
        this.sentence2 = res.sentence2;
        setTimeout(() => {
          this.show2 = true;
          setTimeout(() => {
            this.show2 = false;
            setTimeout(() => {
              this.show3 = true;
              setTimeout(() => {
                this.show3 = false;
                setTimeout(() => {
                  this.show4 = true;
                  setTimeout(() => {
                    this.show4 = false;
                    setTimeout(() => {
                      this.show5 = true;
                      setTimeout(() => {
                        this.reset()
                      }, 3000);
                    }, 2000);
                  }, 3000);
                }, 2000);
              }, 3000);
            }, 2000);
          }, 3000);
        }, 2000);
      } else if (res.code == 106) {
        this.$message.error("你已加入Bar Party，请出示HKU ONE公众号推送以进入！");
        this.reset()
      } else if (res.code == 109) {
        this.$message.error("你不在Bar Party的邀请名单中，请联系OC！");
        this.reset()
      } else {
        if(res.code == 104 && this.show1){
          this.show1 = false;
          setTimeout(() => {
            this.show_follow = true;
          }, 2000);
        }
        setTimeout(() => {
          this.checkLogin();
        }, 100);
      }
    },
    reset () {
      this.show0= false
      this.show1= false
      this.show2= false
      this.show3= false
      this.show4= false
      this.show5= false
      this.show_follow= false
      this.qrcode_url= ""
      this.auth_key= ""
      this.logining= true
      this.user_name= ""
      this.sentence1= ""
      this.sentence2= ""
      setTimeout(() => {
        this.show0 = true;
      }, 3000);
    }
  },
};
</script>

<style lang="less" scoped>
.body1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.body1 {
  background: #000;
  text-align: center;
}
.body1::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.scene {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  perspective: 15px;
  perspective-origin: 50% 50%;
}

.wrap {
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: move 12s infinite linear;
  animation-fill-mode: forwards;
}

.wrap:nth-child(2) {
  animation: move 12s infinite linear;
  animation-delay: 6s;
}

.wall {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(/img/sg.jpg);
  background-size: cover;
  opacity: 0;
  animation: fade 12s infinite linear;
}

.wrap:nth-child(2) .wall {
  animation-delay: 6s;
}

.wall-right {
  transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
  transform: rotateY(-90deg) translateZ(500px);
}

.wall-top {
  transform: rotateX(90deg) translateZ(500px);
}

.wall-bottom {
  transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
  transform: rotateX(180deg) translateZ(500px);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes move {
  0% {
    transform: translateZ(-500px) rotate(0deg);
  }
  100% {
    transform: translateZ(500px) rotate(0deg);
  }
}
.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active {
  transition: opacity 1000ms linear;
}
div {
  font-family: "Arial";
}
</style>